import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'

export default class CarouselController extends Controller {
  static targets = ['container', 'pagination']

  connect () {
    this.initSwiper()
  }

  initSwiper () {
    Swiper.use([Navigation, Pagination])

    const mySwiper = new Swiper(this.containerTarget, {
      direction: 'horizontal',
      slidesPerView: 1,
      hashNavigation: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
    })
  }
}
