import { Controller } from 'stimulus'

export default class AccordionController extends Controller {
  static targets = ['content']

  toggle (e) {
    e.preventDefault()
    this.contentTarget.classList.toggle('is-hidden')
  }
}
